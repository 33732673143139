import React from 'react';
import DATA from './categoriesData';

const GetCategoryIconsWithSize = ({icon, size}) => {
  if (icon) {
    if (DATA[icon]) {
      return React.cloneElement(DATA[icon].icon, {
        // Add additional props to each child element
        size: size,
      });
      //   return DATA[icon].icon;
    } else {
      return React.cloneElement(DATA.Default.icon, {
        // Add additional props to each child element
        size: size,
      });
    }
  } else {
    return React.cloneElement(DATA.Default.icon, {
      // Add additional props to each child element
      size: size,
    });
  }
};

export default GetCategoryIconsWithSize;
