import { Dropdown, Space } from 'antd';
import React, { useState } from 'react';
import ArrowSVG from '../../icons/ArrowSVG';
import './MyDropDown.scss';

const MyDropDown = ({
  items = [],
  children,
  arrow = true,
  align,
  disableMarginTop,
  gap,
  padding,
  marginTop,
  dropdownRender,
  onOpenChange,
}) => {
  const [isOpen, setisOpen] = useState(false);

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      dropdownRender={(menu) =>
        dropdownRender || <div className="my-dropdown-menu">{menu}</div>
      }
      onOpenChange={(val) => {
        setisOpen(val);
        onOpenChange && onOpenChange(val);
      }}
      //   arrow={arrow}
      className="dropdown-test"
    >
      <Space
        align={align || 'start'}
        style={{ gap: gap, padding: padding, marginTop }}
      >
        {children}
        <div style={{ marginTop: disableMarginTop ? 0 : 5 }}>
          {arrow ? (
            <div className="arrow-bg">
              <ArrowSVG rotate={isOpen ? 180 : 0} />
            </div>
          ) : null}
        </div>
      </Space>
    </Dropdown>
  );
};

export default MyDropDown;
