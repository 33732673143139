import React from 'react';
import { PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ApiStorage from '../../redux/ApiStorage';
import MyLink from '../../components/myLink/MyLink';
import LogoSVG from '../../icons/LogoSVG';
import MyDropDown from '../../components/myDropDown/MyDropDown';
import { useSelector } from 'react-redux';
import MyText from '../../components/myText/MyText';
import { useNavigate } from 'react-router-dom';
import UsersActions from '../../actions/UsersActions';
import { Avatar, Flex } from 'antd';

const UserProfileMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sideMenuColapsed = useSelector((state) => {
    return state.sideMenuColapsed;
  });

  const currentUser = useSelector((state) => {
    return state.currentUser;
  });

  const currentUserName = currentUser?.displayName || '';

  const items = [
    {
      icon: <UserOutlined />,
      label: (
        <MyLink path="/users/account_settings">{t('WORD-PROFILE')}</MyLink>
      ),
      key: 'AccountSettings',
    },
    {
      icon: <PoweroffOutlined />,
      label: t('WORD_LOG_OUT'),
      key: `logOut`,
      onClick: async () => {
        await UsersActions.logOut();
        ApiStorage.clearUser();
        navigate(`/login`);
      },
    },
  ];

  return (
    <MyDropDown
      items={items}
      arrow={false}
      align={'center'}
      disableMarginTop
      padding={'0 0 0 5px'}
      marginTop={'auto'}
    >
      {sideMenuColapsed ? (
        <LogoSVG />
      ) : (
        <Flex align="center" gap={5}>
          <Avatar src={currentUser.icon} size={40} shape="square" />
          <MyText size={16} fontWeight={400} color={'#A7AAB5'}>
            {currentUserName}
          </MyText>
        </Flex>
      )}
    </MyDropDown>
  );
};

export default UserProfileMenu;
