import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.6" cx="19" cy="19" r="19" fill="#C93C63" />
      <path
        d="M13 25.8L18.9 19.9L24.8 14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9998 14L18.8998 19.9L24.7998 25.8"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SVGDeclineBudgetShareIcon = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(SVGDeclineBudgetShareIcon);
