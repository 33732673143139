import React, { useEffect, useState } from 'react';
import { Drawer, Layout, Menu, Space } from 'antd';
import ActivityOutlineSVG from '../../icons/ActivityOutlineSVG';
import { useSelector } from 'react-redux';
import ApiStorage from '../../redux/ApiStorage';
import BudgetsOutlineSVG from '../../icons/BudgetsOutlineSVG';
import TransactionsOutlineSVG from '../../icons/TransactionsOutlineSVG';
import AccountsOutlineSVG from '../../icons/AccountsOutlineSVG';
import HeaderMenuLeft from './HeaderMenuLeft';
import useMyTheme from '../../theme/useMyTheme';
import MyLink from '../../components/myLink/MyLink';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useWindowSize from '../../utils/useWindowSize';
import './MainLayoutSideMenu.scss';
import TransactionsFilledSVG from '../../icons/TransactionsFilledSVG';
import ActivityFilledSVG from '../../icons/ActivityFilledSVG';
import BudgetsFilledSVG from '../../icons/BudgetsFilledSVG';
import AccountsFilledSVG from '../../icons/AccountsFilledSVG';
import UserProfileMenu from './UserProfileMenu';
import SettingsOutlineSVG from '../../icons/SettingsOutlineSVG';
import SettingsFilledSVG from '../../icons/SettingsFilledSVG';

const { Sider } = Layout;

const MainLayoutSideMenu = () => {
  const { isDark } = useMyTheme();
  const location = useLocation();

  const { t } = useTranslation();

  const [selectedMenuKey, setselectedMenuKey] = useState('');

  const { isDesktop } = useWindowSize();

  const sideMenuColapsed = useSelector((state) => {
    return state.sideMenuColapsed;
  });
  const sidePanelOpen = useSelector((state) => {
    return state.sidePanelOpen;
  });

  useEffect(() => {
    if (location?.pathname.startsWith('/activity')) {
      setselectedMenuKey('activity');
    } else if (location?.pathname.startsWith('/category')) {
      setselectedMenuKey('category');
    } else if (location?.pathname.startsWith('/transaction')) {
      setselectedMenuKey('transaction');
    } else if (location?.pathname.startsWith('/account')) {
      setselectedMenuKey('account');
    } else {
      setselectedMenuKey('');
    }
  }, [location?.pathname]);

  const items = [
    {
      key: 'activity',
      label: (
        <MyLink style={{ fontSize: 16 }} path="/activity" state={{}}>
          {t('WORD_ACTIVITY')}
        </MyLink>
      ),
      icon:
        selectedMenuKey === 'activity' ? (
          <ActivityFilledSVG />
        ) : (
          <ActivityOutlineSVG />
        ),
    },
    {
      key: 'category',
      label: (
        <MyLink style={{ fontSize: 16 }} path="/category/list" state={{}}>
          {t('WORD_BUDGET')}
        </MyLink>
      ),
      icon:
        selectedMenuKey === 'category' ? (
          <BudgetsFilledSVG />
        ) : (
          <BudgetsOutlineSVG />
        ),
    },
    {
      key: 'transaction',
      label: (
        <MyLink style={{ fontSize: 16 }} path="/transaction/list" state={{}}>
          {t('WORD_ALL_TRANSACTION')}
        </MyLink>
      ),
      icon:
        selectedMenuKey === 'transaction' ? (
          <TransactionsFilledSVG />
        ) : (
          <TransactionsOutlineSVG />
        ),
    },
    {
      key: 'account',
      label: (
        <MyLink style={{ fontSize: 16 }} path="/accounts/list" state={{}}>
          {t('WORD_ACCOUNTS')}
        </MyLink>
      ),
      icon:
        selectedMenuKey === 'account' ? (
          <AccountsFilledSVG />
        ) : (
          <AccountsOutlineSVG />
        ),
    },
    {
      key: 'settings',
      label: (
        <MyLink style={{ fontSize: 16 }} path="/dbbudget/settings" state={{}}>
          {t('WORD_BUDGET_SETTINGS')}
        </MyLink>
      ),
      icon:
        selectedMenuKey === 'settings' ? (
          <SettingsFilledSVG />
        ) : (
          <SettingsOutlineSVG />
        ),
    },
  ];

  const handleClose = () => {
    ApiStorage.setSidePanelOpen(false);
    // onCloseToggle && onCloseToggle()
  };

  const MainSideBarContent = () => {
    return (
      <Space.Compact
        direction="vertical"
        block="true"
        style={{ height: '100%' }}
      >
        <div className="side-menu-header">
          <HeaderMenuLeft />
        </div>
        <Menu
          theme={isDark ? 'dark' : 'light'}
          className="main-side-menu"
          defaultSelectedKeys={['1']}
          mode="inline"
          items={items}
          selectedKeys={[selectedMenuKey]}
          onSelect={(menu) => {
            handleClose();
            setselectedMenuKey(menu?.key);
          }}
        />
        <UserProfileMenu />
      </Space.Compact>
    );
  };

  return isDesktop ? (
    <Sider
      theme="light"
      // collapsible
      collapsed={sideMenuColapsed}
      onCollapse={ApiStorage.setSideMenuColapsed}
      width={245}
      // trigger={<MenuSVG />}
    >
      <MainSideBarContent />
    </Sider>
  ) : (
    <Drawer
      rootClassName="main_layout_side_menu"
      placement={'left'}
      closable={false}
      onClose={handleClose}
      open={sidePanelOpen}
      key={'left'}
      width={230}
    >
      <MainSideBarContent />
    </Drawer>
  );
};

export default MainLayoutSideMenu;
