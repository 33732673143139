import { Flex } from 'antd';
import React from 'react';

const MyDefaultIconImage = ({ url, size }) => {
  return (
    <Flex style={{ width: size || 36, height: size || 36 }} align="center">
      <img
        style={{ width: size || 36, height: size || 36 }}
        src={url}
        alt="categoryIcon"
      />
    </Flex>
  );
};

export default MyDefaultIconImage;
