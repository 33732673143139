import React, { forwardRef, useImperativeHandle } from 'react';
import Div from '../../../components/div/Div';
import { useState } from 'react';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import { useNavigate } from 'react-router-dom';
import BudgetActions from '../../../actions/BudgetActions';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SectionDrawer from './SectionDrawer';
import { useEffect } from 'react';

const HeaderMenuLeftDropdownRender = forwardRef((_, ref) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setisLoading] = useState(true);
  const [data, setdata] = useState([]);

  const fetch = async () => {
    setisLoading(true);

    let result = await BudgetActions.getAllByUser();
    if (result.success) {
      setdata(result.data);
    }
    setisLoading(false);
  };

  useImperativeHandle(ref, () => ({
    fetchBudgets: async () => {
      fetch();
    },
  }));

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Div
      ref={ref}
      className={`header_menu_left_dropdown ${isLoading ? 'loading' : ''}`}
    >
      {Boolean(isLoading) ? (
        <LoadingOutlined style={{ fontSize: 30 }} />
      ) : (
        <Div className={'header_menu_left_dropdown--body'}>
          <SectionDrawer
            title={'My budgets'}
            data={data?.filter((item) => item.isOwner)}
            onChange={() => {
              fetch();
            }}
          />
          <SectionDrawer
            title={'Shared with you'}
            data={data?.filter((item) => !item.isOwner)}
            onChange={() => {
              fetch();
            }}
          />
          <MyButtonMain
            onClick={() => {
              navigate('/dbbudget/create');
            }}
            label={t('WORD_CREATE_NEW_BUDGET')}
            type="default"
            fullWidth
          />
        </Div>
      )}
    </Div>
  );
});

export default HeaderMenuLeftDropdownRender;
