import { Button, Dropdown, Flex } from 'antd';
import React from 'react';
import SVGDropdownArrow from '../../icons/SVGDropdownArrow';
import colors from '../../Color.scss';
import SVGRemoveFilterIcon from '../../icons/SVGRemoveFilterIcon';
import './MyDefaultDropDown.scss';

const MyDefaultDropDown = ({
  menuProps,
  title,
  disableIcon,
  className,
  inactive,
  onClose,
}) => {
  return (
    <React.Fragment>
      <Dropdown menu={menuProps}>
        <Button
          className={`my-button-secondary-filled ${className || ''} ${
            inactive ? 'inactive' : ''
          } ${onClose ? 'has_close_icon' : ''}`}
          type="primary"
        >
          <Flex gap={10} align="center">
            {title}
            {Boolean(!disableIcon) && (
              <React.Fragment>
                {onClose ? (
                  <SVGRemoveFilterIcon
                    onClick={onClose}
                    style={{ position: 'absolute', right: 8 }}
                  />
                ) : (
                  <SVGDropdownArrow
                    style={{
                      pointerEvents: 'none',
                    }}
                    color={inactive ? colors.bluishColor : colors.primaryColor}
                  />
                )}
              </React.Fragment>
            )}
          </Flex>
        </Button>
      </Dropdown>
    </React.Fragment>
  );
};

export default MyDefaultDropDown;
