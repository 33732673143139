import { Avatar, Flex } from 'antd';
import React from 'react';
import BudgetShareActions from '../../../actions/BudgetShareActions';
import BudgetActions from '../../../actions/BudgetActions';
import SVGDefaultBudgetIconFilled from '../../../icons/SVGDefaultBudgetIconFilled';
import ApiStorage from '../../../redux/ApiStorage';
import colors from '../../../Color.scss';
import UtilDate from '../../../utils/UtilDate';
import { useTranslation } from 'react-i18next';
import SVGDeclineBudgetShareIcon from '../../../icons/SVGDeclineBudgetShareIcon';
import IconRound from '../../../components/iconRound/IconRound';
import MyText from '../../../components/myText/MyText';
import SVGApproveBudgetShareIcon from '../../../icons/SVGApproveBudgetShareIcon';
import UtilNotify from '../../../utils/UtilNotify';

const RenderItem = ({
  item,
  currentDbIdkey,
  navigate,
  currentUserId,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleReject = async () => {
    let result = await BudgetShareActions.unShare(item.idKey);
    if (result.success) {
      UtilNotify.notifySuccess('Deny success');
      onChange && onChange();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  const handleAccept = async () => {
    let result = await BudgetShareActions.accept(item.idKey);
    if (result.success) {
      UtilNotify.notifySuccess('Accept success');
      onChange && onChange();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  return (
    <Flex
      align="center"
      justify="space-between"
      className={`item ${item.idKey === currentDbIdkey ? 'active' : ''}`}
      onClick={async () => {
        if (item.status === BudgetShareActions.STATUS.ACTIVE) {
          ApiStorage.setCurrentDb(item);
          let result = await BudgetActions.getSettings();
          if (result.success) {
            navigate('/home');
          }
        }
      }}
    >
      <Flex gap={9} align="center">
        <SVGDefaultBudgetIconFilled
          size={38}
          backgroundColor={item.idKey === currentDbIdkey && colors.primaryColor}
          colorr={item.idKey === currentDbIdkey && colors.whiteColor}
        />
        <Flex vertical>
          <span className="displayName">{item.displayName}</span>
          <span className="lastSyncDate">
            {item?.lastSyncDate
              ? `${t('WORD_LAST_SYNC')} ${UtilDate.formatDate(
                  item?.lastSyncDate
                )}`
              : t('WORD_NEVER_SYNCED')}
          </span>
        </Flex>
      </Flex>
      {item.status === BudgetShareActions.STATUS.PENDING ? (
        <Flex gap={6} align="center">
          <SVGDeclineBudgetShareIcon
            onClick={() => {
              handleReject();
            }}
          />
          <SVGApproveBudgetShareIcon
            onClick={() => {
              handleAccept();
            }}
          />
        </Flex>
      ) : (
        <Avatar.Group>
          {item.accessUsers
            .filter((it) => currentUserId && it.idUser !== currentUserId)
            .map((us) => {
              return (
                <MyText>
                  <IconRound name={us.displayName} src={us.icon} width={33} />
                </MyText>
              );
            })}
        </Avatar.Group>
      )}
    </Flex>
  );
};

export default RenderItem;
