import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || '28'}
      height={size || '28'}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_283_609)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.2744 8.89417L23.5482 7.63406C22.9339 6.56782 21.5725 6.19999 20.5048 6.81179V6.81179C19.9965 7.11119 19.3901 7.19613 18.8191 7.04789C18.2482 6.89964 17.7597 6.53039 17.4613 6.02155C17.2693 5.69813 17.1662 5.32975 17.1623 4.95366V4.95366C17.1796 4.35071 16.9521 3.76642 16.5317 3.3339C16.1112 2.90138 15.5336 2.65746 14.9304 2.65771H13.4674C12.8764 2.6577 12.3098 2.89319 11.893 3.31205C11.4761 3.73092 11.2433 4.29864 11.2462 4.88959V4.88959C11.2287 6.1097 10.2345 7.08956 9.0143 7.08944C8.63822 7.08553 8.26984 6.98239 7.94641 6.79043V6.79043C6.87871 6.17863 5.51727 6.54646 4.90294 7.6127L4.12338 8.89417C3.50979 9.95908 3.87261 11.3197 4.93497 11.9376V11.9376C5.62553 12.3363 6.05093 13.0731 6.05093 13.8705C6.05093 14.6679 5.62553 15.4047 4.93497 15.8034V15.8034C3.87396 16.4172 3.51074 17.7745 4.12338 18.8362V18.8362L4.86022 20.107C5.14806 20.6264 5.63101 21.0096 6.2022 21.172C6.77339 21.3343 7.38572 21.2623 7.9037 20.972V20.972C8.4129 20.6748 9.0197 20.5934 9.5892 20.7458C10.1587 20.8983 10.6437 21.2718 10.9365 21.7836C11.1285 22.107 11.2316 22.4754 11.2355 22.8515V22.8515C11.2355 24.0841 12.2348 25.0833 13.4674 25.0833H14.9304C16.1589 25.0833 17.1564 24.0906 17.1623 22.8621V22.8621C17.1594 22.2693 17.3937 21.7 17.8128 21.2808C18.232 20.8616 18.8014 20.6274 19.3942 20.6302C19.7693 20.6403 20.1362 20.743 20.4621 20.9293V20.9293C21.527 21.5428 22.8875 21.18 23.5055 20.1177V20.1177L24.2744 18.8362C24.572 18.3254 24.6537 17.717 24.5014 17.1457C24.349 16.5745 23.9753 16.0875 23.4628 15.7927V15.7927C22.9504 15.4979 22.5766 15.011 22.4243 14.4397C22.2719 13.8685 22.3536 13.2601 22.6512 12.7492C22.8448 12.4113 23.1249 12.1312 23.4628 11.9376V11.9376C24.5188 11.32 24.8808 9.96736 24.2744 8.90485V8.90485V8.89417Z"
          stroke="#A7AAB5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse
          cx="14.2042"
          cy="13.8705"
          rx="3.07552"
          ry="3.07551"
          stroke="#A7AAB5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_283_609">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const SettingsOutlineSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(SettingsOutlineSVG);
