import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ color }) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="19" fill="#8870E6" />
      <path
        d="M12.7026 18.05L18.0769 23.275L26.3826 15.2"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SVGApproveBudgetShareIcon = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(SVGApproveBudgetShareIcon);
