import React, { memo } from 'react';
import MyIcon from './MyIcon';

const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || '28'}
      height={size || '28'}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_283_599)">
        <path
          opacity="0.4"
          d="M14.0161 17.3018C12.1442 17.3018 10.63 15.8434 10.63 14.0118C10.63 12.1801 12.1442 10.7101 14.0161 10.7101C15.888 10.7101 17.3664 12.1801 17.3664 14.0118C17.3664 15.8434 15.888 17.3018 14.0161 17.3018Z"
          fill="#8870E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8061 15.84C24.2234 16.0617 24.5453 16.4117 24.7718 16.7617C25.213 17.485 25.1772 18.3717 24.748 19.1533L23.9134 20.5533C23.4722 21.3 22.6496 21.7667 21.803 21.7667C21.3857 21.7667 20.9207 21.65 20.5392 21.4167C20.2292 21.2183 19.8715 21.1483 19.49 21.1483C18.3096 21.1483 17.32 22.1167 17.2843 23.2717C17.2843 24.6133 16.1874 25.6633 14.8163 25.6633H13.1947C11.8117 25.6633 10.7148 24.6133 10.7148 23.2717C10.6909 22.1167 9.70135 21.1483 8.52099 21.1483C8.12754 21.1483 7.76985 21.2183 7.47178 21.4167C7.09025 21.65 6.61334 21.7667 6.20796 21.7667C5.34952 21.7667 4.52684 21.3 4.0857 20.5533L3.26302 19.1533C2.82188 18.395 2.79803 17.485 3.23918 16.7617C3.42994 16.4117 3.78763 16.0617 4.193 15.84C4.52684 15.6767 4.74145 15.4083 4.94414 15.0933C5.54028 14.09 5.1826 12.7717 4.16916 12.1767C2.9888 11.5117 2.60727 10.03 3.28687 8.875L4.0857 7.49833C4.77722 6.34333 6.25565 5.935 7.44794 6.61167C8.48522 7.17167 9.8325 6.79834 10.4406 5.80667C10.6313 5.48 10.7386 5.13 10.7148 4.78C10.6909 4.325 10.8221 3.89334 11.0486 3.54334C11.4898 2.82 12.2886 2.35334 13.159 2.33H14.8401C15.7224 2.33 16.5212 2.82 16.9624 3.54334C17.177 3.89334 17.32 4.325 17.2843 4.78C17.2604 5.13 17.3677 5.48 17.5585 5.80667C18.1666 6.79834 19.5138 7.17167 20.5631 6.61167C21.7434 5.935 23.2338 6.34333 23.9134 7.49833L24.7122 8.875C25.4037 10.03 25.0222 11.5117 23.8299 12.1767C22.8165 12.7717 22.4588 14.09 23.0669 15.0933C23.2576 15.4083 23.4722 15.6767 23.8061 15.84ZM10.6313 14.0083C10.6313 15.84 12.1455 17.2983 14.0174 17.2983C15.8893 17.2983 17.3677 15.84 17.3677 14.0083C17.3677 12.1767 15.8893 10.7067 14.0174 10.7067C12.1455 10.7067 10.6313 12.1767 10.6313 14.0083Z"
          fill="#8870E6"
        />
      </g>
      <defs>
        <clipPath id="clip0_283_599">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const SettingsFilledSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(SettingsFilledSVG);
