import React from 'react';
import { Form, DatePicker } from 'antd';
import './InputFormRangeDatePicker.scss';
import UtilDate from '../../utils/UtilDate';

const { RangePicker } = DatePicker;

const InputFormRangeDatePicker = ({
  label,
  name = 'DateValue',
  required = false,
  placeholder = 'Select Date',
  hidden,
  style,
  readOnly = false,
  allowClear = true,
  onChange,
  hasFeedback = false,
  format = 'DD/MM/YYYY',
}) => {
  const rangePresets = [
    {
      label: 'Today',
      value: [UtilDate.getStartDateToday(), UtilDate.getDateToday()],
    },
    {
      label: 'This Month',
      value: [UtilDate.getThisMonthStart(), UtilDate.getDateToday()],
    },
    {
      label: 'Last 30 Days',
      value: [
        UtilDate.getDateWithCustomMinusPlusDays(false, -30),
        UtilDate.getDateToday(),
      ],
    },
  ];

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required && !hidden,
          message: `Please input ${placeholder}!`,
        },
      ]}
      layout="vertical"
      hidden={hidden}
      hasFeedback={hasFeedback}
      style={style}
      className="input-range-picker"
    >
      <RangePicker
        style={{ width: '100%' }}
        inputReadOnly={readOnly}
        allowClear={(!readOnly && allowClear) || false}
        className="my-date-picker"
        allowEmpty={[true, true]}
        placeholder={['From', 'To']}
        presets={rangePresets}
        format={format}
        onChange={(e) => {
          if (!readOnly) {
            const localDateFrom =
              e?.[0]?.startOf('day')?.format('YYYY-MM-DD') || undefined;
            const localDateTo =
              e?.[1]?.startOf('day')?.format('YYYY-MM-DD') || undefined;
            // setIsOpen(false);
            onChange && onChange(e, localDateFrom, localDateTo);
          }
        }}
        // open={isOpen}
      />
    </Form.Item>
  );
};

export default InputFormRangeDatePicker;
