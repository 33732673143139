import React from 'react';
import Div from '../../../components/div/Div';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RenderItem from './RenderItem';

const SectionDrawer = ({ data, title, onChange }) => {
  const navigate = useNavigate();

  const currentUserId = useSelector((state) => {
    return state.currentUser?.idUser;
  });
  const currentDbIdkey = useSelector((state) => {
    return state?.currentDb?.idKey || '';
  });

  return (
    <Div>
      <span className="title">{title}</span>
      <Div className={'items_wrapper'}>
        {data.map((item) => {
          return (
            <RenderItem
              currentDbIdkey={currentDbIdkey}
              currentUserId={currentUserId}
              item={item}
              navigate={navigate}
              onChange={onChange}
            />
          );
        })}
      </Div>
    </Div>
  );
};

export default SectionDrawer;
