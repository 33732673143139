import React, { useCallback, useEffect, useState } from 'react';
import MyPageHeader from '../../../components/myPageHeader/MyPageHeader';
import MyButtonMain from '../../../components/myButton/MyButtonMain';
import MyList from '../../../components/myList/MyList';
import BudgetListItem from './BudgetListItem';
import MySpace from '../../../components/mySpace/MySpace';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ApiStorage from '../../../redux/ApiStorage';
import BudgetActions from '../../../actions/BudgetActions';
import { useSelector } from 'react-redux';
import UtilNotify from '../../../utils/UtilNotify';
import BudgetListItemShare from './BudgetListItemShare';
import { Helmet } from 'react-helmet';

const BudgetList = (props) => {
  const { isFirst } = props;

  const currentDbdbName = useSelector((state) => {
    return state.currentDb?.dbName || '';
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [budgetList, setBudgetList] = useState([]);

  const fill = useCallback(async () => {
    let result = await BudgetActions.getAllByUser();
    if (result.success) {
      setBudgetList(
        result.data.map((db) => {
          db.isSelected = db.dbName === currentDbdbName;
          return db;
        })
      );
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  }, [currentDbdbName]);

  useEffect(() => {
    fill();
  }, [fill]);

  return (
    <>
      <Helmet>
        <title>
          {t('WORD_BUDGET_LIST')} | {t('WORD_MONEYSCOPE')}
        </title>
        <meta name="description" content="Some description." />
        <meta
          property="og:title"
          content={`${t('WORD_BUDGET_LIST')}` | `${t('WORD_MONEYSCOPE')}`}
        />
        <meta property="og:image" content="public/favicon/mstile-150x150.png" />
      </Helmet>
      <div
        style={{
          width: isFirst ? '45%' : '100%',
        }}
      >
        <MySpace
          fullWidth
          spaceBetween
          style={{
            padding: isFirst ? 10 : 15,
          }}
        >
          <MyPageHeader
            onBack={
              isFirst &&
              (() => {
                ApiStorage.clearUser();
                navigate('/login');
              })
            }
            title={t('WORD_MY_BUDGETS')}
          />
          <MyButtonMain
            label={t('WORD_NEW_BUDGET')}
            onClick={() => {
              if (isFirst) {
                navigate('/dbbudget/create/first');
              } else {
                navigate('/dbbudget/create');
              }
            }}
          />
        </MySpace>
        <MyList
          grid={{ gutter: isFirst ? 20 : 10 }}
          emptyText={t('WORD_NO_DATA_BUDGET')}
          style={{ marginTop: 20 }}
          data={budgetList?.filter((it) => it.isOwner)}
          renderItem={(item) => {
            return <BudgetListItem item={item} />;
          }}
        />
        <MySpace size="middle" direction="vertical" style={{ marginTop: 10 }}>
          <MyPageHeader title={t('WORD_BUDGETS_SHARED_WITH_YOU')} />
          <MyList
            grid={{
              gutter: 20,
            }}
            emptyText={t('WORD_NO_DATA_SHARED')}
            data={budgetList.filter((it) => !it.isOwner)}
            renderItem={(item) => {
              return <BudgetListItemShare item={item} onChange={fill} />;
            }}
          />
        </MySpace>
      </div>
    </>
  );
};

export default BudgetList;
