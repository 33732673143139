import React, { forwardRef, useImperativeHandle } from 'react';
import { DatePicker } from 'antd';
import MyDefaultDropDown from '../myDefaultDropDown/MyDefaultDropDown';
import UtilDate from '../../utils/UtilDate';
import './MyDefaultRangePicker.scss';
import SVGRemoveFilterIcon from '../../icons/SVGRemoveFilterIcon';

const { RangePicker } = DatePicker;

const CustomInput = forwardRef((props, ref) => {
  const { onFocus, dates } = props;

  useImperativeHandle(ref, () => ({
    focus: () => {
      onFocus?.();
    },
  }));

  if (props.placeholder === 'Start date') {
    return null;
  }

  return (
    <MyDefaultDropDown
      className={dates?.length && 'close_icon_padding'}
      menuProps={{ items: [], onClick: () => {} }}
      title={`Date${
        dates?.length ? `: ${UtilDate.getDateLabelForRange(dates)}` : ''
      }`}
      disableIcon={dates?.length}
      inactive={!dates?.length}
    />
  );
});

const MyDefaultRangePicker = ({ dates, onDateChange }) => {
  const rangePresets = [
    {
      label: 'Today',
      value: [UtilDate.getStartDateToday(), UtilDate.getDateToday()],
    },
    {
      label: 'This Month',
      value: [UtilDate.getThisMonthStart(), UtilDate.getDateToday()],
    },
    {
      label: 'Last 30 Days',
      value: [
        UtilDate.getDateWithCustomMinusPlusDays(false, -30),
        UtilDate.getDateToday(),
      ],
    },
  ];

  const handleChange = (value) => {
    onDateChange && onDateChange(value);
  };

  return (
    <RangePicker
      className="my_default_range_picker"
      value={dates}
      onChange={handleChange}
      presets={rangePresets}
      suffixIcon={null}
      separator={null}
      allowClear={{ clearIcon: <SVGRemoveFilterIcon /> }}
      components={{
        input: forwardRef((props, ref) => (
          <CustomInput {...props} ref={ref} dates={dates} />
        )),
      }}
    />
  );
};

export default MyDefaultRangePicker;
