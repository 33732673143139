import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LogoSVG from '../../icons/LogoSVG';
import MyDropDown from '../../components/myDropDown/MyDropDown';
import { useSelector } from 'react-redux';
import MyText from '../../components/myText/MyText';
import MySpace from '../../components/mySpace/MySpace';
import UtilDate from '../../utils/UtilDate';
import HeaderMenuLeftDropdownRender from './headerMenuLeftDropdown/HeaderMenuLeftDropdownRender';
import SVGDefaultBudgetIconFilled from '../../icons/SVGDefaultBudgetIconFilled';

const HeaderMenuLeft = () => {
  const { t } = useTranslation();

  const sideMenuColapsed = useSelector((state) => {
    return state.sideMenuColapsed;
  });

  const currentDbSettingsdisplayName = useSelector((state) => {
    return state.currentDbSettings?.displayName || '';
  });

  const lastSyncDate = useSelector((state) => {
    return state.currentDb?.lastSyncDate || '';
  });

  const dropdownRef = useRef(null);

  return (
    <MyDropDown
      items={[]}
      arrow={false}
      align={'center'}
      disableMarginTop
      padding={'6px 5px'}
      onOpenChange={(val) => {
        if (val && dropdownRef?.current) {
          dropdownRef.current.fetchBudgets();
        }
      }}
      dropdownRender={<HeaderMenuLeftDropdownRender ref={dropdownRef} />}
    >
      {sideMenuColapsed ? (
        <LogoSVG />
      ) : (
        <MySpace size={5}>
          <SVGDefaultBudgetIconFilled size={40} />
          <MySpace
            direction="vertical"
            // align="center"
            // size={10}
            size={0}
            style={{
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <MyText size={16} fontWeight={700} lineHeight={'20px'}>
              {currentDbSettingsdisplayName}
            </MyText>
            <MyText
              size={12}
              fontWeight={400}
              color={'#A7AAB5'}
              lineHeight={'15px'}
            >
              {lastSyncDate
                ? `${t('WORD_LAST_SYNC')} ${UtilDate.formatDate(lastSyncDate)}`
                : t('WORD_NEVER_SYNCED')}
            </MyText>
          </MySpace>
        </MySpace>
      )}
    </MyDropDown>
  );
};

export default HeaderMenuLeft;
