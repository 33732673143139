import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormApp from '../../components/formApp/FormApp';
import InfiniteScrollTable from '../../components/infiniteScrollTable/InfiniteScrollTable';
import MyText from '../../components/myText/MyText';
import InputTextFormItem from '../../components/myInputForm/InputTextFormItem';
import InputNumberFormItem from '../../components/myInputForm/InputNumberFormItem';
import { Flex, Form } from 'antd';
import UtilDate from '../../utils/UtilDate';
import UtilNumber from '../../utils/UtilNumber';
import MyButtonMain from '../../components/myButton/MyButtonMain';
import InputFormDatePicker from '../../components/inputFormDatePicker/InputFormDatePicker';
import TransactionActions from '../../actions/TransactionActions';
import UtilNotify from '../../utils/UtilNotify';
import { useTranslation } from 'react-i18next';
import GetTransactionIcons from '../../components/defaultBudgetCategories/GetTransactionIcons';
import SelectCategory from '../../components/selects/SelectCategory';
import SelectAccount from '../../components/selects/SelectAccount';
import { useSelector } from 'react-redux';
import SelectPayee from '../../components/selects/SelectPayee';
import { useLocation, useNavigate } from 'react-router-dom';
import MyButtonSecondary from '../../components/myButton/MyButtonSecondary';
import MyDefaultSortArrows from '../../components/infiniteScrollTable/MyDefaultSortArrows';
import SVGTableSelectedIcon from '../../icons/SVGTableSelectedIcon';
import MyDefaultPageLayout from '../../components/myDefaultPageLayout/MyDefaultPageLayout';
import SVGApprovedTransactionIcon from '../../icons/SVGApprovedTransactionIcon';
import SVGPendingTransactionIcon from '../../icons/SVGPendingTransactionIcon';
import MyDefaultCenteredColumn from '../../components/infiniteScrollTable/MyDefaultCenteredColumn';
import SVGPlusIcon from '../../icons/SVGPlusIcon';
import MyDefaultDropdownButton from '../../components/myDefaultDropdownButton/MyDefaultDropdownButton';
import { CloudUploadOutlined } from '@ant-design/icons';
import TransactionHeaderFilters from './transactionHeaderFilters/TransactionHeaderFilters';
import TableHeaderComponent from './tableHeaderComponent/TableHeaderComponent';
import TransactionEditModal from './transactionEdit/TransactionEditModal';
import './TransactionList.scss';

const formName = 'transaction-list-form';

const TransactionList = () => {
  const navigate = useNavigate();
  const canEdit = useSelector((state) => state.canEdit);

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const location = useLocation();

  const { accountObject, categoryObject } = location?.state || {};

  const defaultFilter = {
    page: 0,
    perPage: 50,
    search: '',
    sort: '',
    ...location?.state,
    idAccount: accountObject?.idAccount || [],
    idCategory: categoryObject?.idCategory || [],
    type: [
      TransactionActions.TYPE.EXPENSE,
      TransactionActions.TYPE.INCOME,
      TransactionActions.TYPE.TRANSFER,
    ],
    DateRange: [UtilDate.getThisMonthStart(), UtilDate.getDate()],
    dateFrom: UtilDate.getThisMonthStart().format('YYYY-MM-DD'),
    dateTo: UtilDate.getDateToday().format('YYYY-MM-DD'),
  };

  const [listData, setlistData] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [totals, settotals] = useState({});
  const [editingRow, seteditingRow] = useState(null);
  const [selectedRow, setselectedRow] = useState('');
  const [filter, setfilter] = useState(defaultFilter);
  const [loading, setloading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const filtersDrawerRef = useRef();
  const layoutRef = useRef();
  const refModal = useRef();
  const tableRef = useRef(null);
  const inputSearchRef = useRef(null);

  const budgetName = useSelector((state) => {
    return state.currentDbSettings.displayName || '';
  });

  // useEffect(() => {
  //   socketAction.setSocketCallBack_Transactions((tr) => {
  //     if (tr._action === 'INSERT') {
  //       const newArray = [...listData];
  //       newArray.splice(
  //         selectedRow,
  //         // 1,
  //         0,
  //         ...tr.data.map((oo) => {
  //           return {
  //             ...oo,
  //             transactionDate: UtilDate.getDate(oo.transactionDate),
  //           };
  //         })
  //       );
  //       setlistData(newArray);
  //     } else if (tr._action === 'UPDATE') {
  //       const newArray = [
  //         ...listData.filter(
  //           (oo) => !tr.data.map((ooo) => ooo.idKey).includes(oo.idKey)
  //         ),
  //       ];
  //       setlistData(newArray);

  //       //   setlistData((ov) => {
  //       //     return ov.filter(
  //       //       (oo) => !tr.data.map((ooo) => ooo.idKey).includes(oo.idKey)
  //       //     );
  //       //     // .map((oo) => {
  //       //     //   let found = tr.data.find((el) => {
  //       //     //     return el.idKey === oo.idKey;
  //       //     //   });
  //       //     //   if (found) {
  //       //     //     oo = Object.assign({}, oo, found);
  //       //     //   }
  //       //     //   return oo;
  //       //     // });
  //       //   });
  //       // }
  //     }
  //   });
  //   return () => {
  //     socketAction.setSocketCallBack_Transactions(null);
  //   };
  // }, [listData, budgetName]);

  const fillFS = useCallback(async () => {
    setloading(true);
    let result = await TransactionActions.fillPaginationList({
      search: filter?.search,
      pagination: {
        page: filter?.page,
        perPage: filter?.perPage,
      },
      filter: {
        dateFrom: filter?.dateFrom,
        dateTo: filter?.dateTo,
        idAccount: filter?.idAccount,
        idCategory: filter?.idCategory,
        idPayee: filter?.idPayee,
        approved: filter?.approved,
        type: filter?.type,
      },
      sort: filter?.sort,
    });
    if (result.success) {
      const data = result.data.map((oo) => {
        return {
          ...oo,
          transactionDate: UtilDate.getDate(oo.transactionDate),
        };
      });
      if (filter?.page === 0) {
        setlistData(data);
      } else {
        setlistData((ov) => [...ov, ...(data ?? [])]);
      }
      settotalRows(result.totalRows);
      settotals(result.totals);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setloading(false);
  }, [filter]);

  useEffect(() => {
    fillFS();
  }, [fillFS]);

  const columns = [
    {
      title: t('WORD_DATE'),
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      sorter: true,
      sortIcon: (sortOrder) => <MyDefaultSortArrows sortOrder={sortOrder} />,
      width: 110,
      render: (value, record) => {
        if (editingRow === record?.idTransaction) {
          return (
            <InputFormDatePicker name="transactionDate" allowClear={false} />
          );
        } else {
          return (
            <MyText size={15} fontWeight={400}>
              {UtilDate.formatDate(value)}
            </MyText>
          );
        }
      },
    },
    {
      title: t('WORD_ACCOUNT'),
      dataIndex: 'accountName',
      key: 'accountName',
      sorter: true,
      sortIcon: (sortOrder) => <MyDefaultSortArrows sortOrder={sortOrder} />,
      width: 160,
      render: (value, record) => {
        if (editingRow === record?.idTransaction) {
          return (
            <SelectAccount
              name="idAccount"
              style={{ width: 130 }}
              required
              disabled={!canEdit}
              onChangeItem={(ii) => {
                if (ii) {
                  form.setFieldsValue({ accountName: ii.accountName });
                }
              }}
            />
          );
        } else {
          return (
            <MyText size={15} fontWeight={400}>
              {value}
            </MyText>
          );
        }
      },
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
      key: 'categoryName',
      sorter: true,
      sortIcon: (sortOrder) => <MyDefaultSortArrows sortOrder={sortOrder} />,
      width: 200,
      render: (value, { idTransaction, categoryName, accountNameTo, type }) => {
        if (editingRow === idTransaction) {
          return (
            <SelectCategory
              style={{ width: 220 }}
              name="idCategory"
              placeholder="Category"
              transactionType={type}
              onChangeItem={(ii) => {
                if (ii) {
                  form.setFieldsValue({
                    categoryName: ii.categoryName,
                    icon: ii.icon,
                  });
                }
              }}
            />
          );
        } else {
          return (
            <MyText size={15} fontWeight={400}>
              {accountNameTo ? `Transfer to ${accountNameTo}` : categoryName}
            </MyText>
          );
        }
      },
    },
    {
      title: 'Payee',
      dataIndex: 'payeeName',
      key: 'payeeName',
      sorter: true,
      sortIcon: (sortOrder) => <MyDefaultSortArrows sortOrder={sortOrder} />,
      width: 160,
      render: (value, record) => {
        if (editingRow === record?.idTransaction) {
          return <SelectPayee name="idPayee" />;
        } else {
          return (
            <MyText size={15} fontWeight={400}>
              {value}
            </MyText>
          );
        }
      },
    },
    {
      title: 'Memo',
      dataIndex: 'transactionNote',
      key: 'transactionNote',
      sorter: true,
      sortIcon: (sortOrder) => <MyDefaultSortArrows sortOrder={sortOrder} />,
      render: (value, record) => {
        if (editingRow === record?.idTransaction) {
          return (
            <InputTextFormItem name="transactionNote" placeholder="Memo" />
          );
        } else {
          return (
            <MyText size={15} fontWeight={400}>
              {value}
            </MyText>
          );
        }
      },
    },
    {
      title: 'Inflow',
      dataIndex: 'valueIncome',
      key: 'valueIncome',
      sorter: true,
      sortIcon: (sortOrder) => <MyDefaultSortArrows sortOrder={sortOrder} />,
      width: 130,
      render: (value, record) => {
        if (editingRow === record?.idTransaction) {
          return (
            <>
              <InputTextFormItem name="idKey" hidden={true} />
              <InputTextFormItem name="categoryName" hidden={true} />
              <InputTextFormItem name="accountName" hidden={true} />
              <InputNumberFormItem name="value" hidden={true} />
              <InputNumberFormItem name="idTransaction" hidden={true} />
              <InputNumberFormItem name="valueIncome" placeholder="Inflow" />
            </>
          );
        } else {
          return (
            <MyText size={15} fontWeight={400}>
              {value ? UtilNumber.formatDouble(value) : ''}
            </MyText>
          );
        }
      },
    },
    {
      title: 'Outflow',
      dataIndex: 'valueExpense',
      key: 'valueExpense',
      sorter: true,
      sortIcon: (sortOrder) => <MyDefaultSortArrows sortOrder={sortOrder} />,
      width: 130,
      render: (value, record) => {
        if (editingRow === record?.idTransaction) {
          return (
            <InputNumberFormItem
              name="valueExpense"
              placeholder="Outflow"
              onChange={(value) => {
                form.setFieldsValue({ valueExpense: value });
              }}
            />
          );
        } else {
          return (
            <MyText size={15} fontWeight={400}>
              {value ? UtilNumber.formatDouble(value) : ''}
            </MyText>
          );
        }
      },
    },
    {
      title: (
        <MyDefaultCenteredColumn>
          <SVGApprovedTransactionIcon />
        </MyDefaultCenteredColumn>
      ),
      dataIndex: 'approved',
      key: 'approved',
      width: 54,
      sorter: true,
      sortIcon: (sortOrder) => null,
      render: (approved) => {
        return (
          <MyDefaultCenteredColumn>
            {approved ? (
              <SVGApprovedTransactionIcon />
            ) : (
              <SVGPendingTransactionIcon />
            )}
          </MyDefaultCenteredColumn>
        );
      },
    },
  ];

  const onChangeTable = (_, __, sorter) => {
    let so = '';
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}`;
    }
    setfilter((ov) => Object.assign({}, ov, { sort: so }));
  };

  const handleSetEditable = (record, index) => {
    if (record) {
      form.setFieldsValue(record);
      seteditingRow(record?.idTransaction);
      setselectedRow(index);
    } else {
      seteditingRow(null);
    }
  };

  const onFinish = async (values) => {
    if (!values.idKey) {
      delete values.idTransaction;
    }
    values.idPayee = values.idPayee || '';
    if (values.valueIncome > 0) {
      values.type = TransactionActions.TYPE.INCOME;
      values.value = values.valueIncome;
    } else {
      values.type = TransactionActions.TYPE.EXPENSE;
      values.value = -values.valueExpense;
    }
    let result = await TransactionActions.saveTransactionForm(
      values,
      values?.idTransaction
    );
    if (result.success) {
      UtilNotify.notifySuccess('WORD_EDITED_SUCCESSFULY');
      seteditingRow(null);
      fillFS();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  const handleAddTransaction = () => {
    refModal.current.open();
    // const idTransaction = new Date().getMilliseconds();
    // const obj = {
    //   idTransaction: idTransaction,
    //   idCurrency: 2,
    //   type: TransactionActions.TYPE.EXPENSE,
    //   status: TransactionActions.STATUS.ACTIVE,
    //   ordering: 1000,
    //   value: '',
    //   valueIncome: '',
    //   valueExpense: '',
    //   transactionNote: null,
    //   accountName: null,
    //   accountNameTo: null,
    //   payeeName: null,
    //   categoryName: '',
    //   idCategory: '',
    //   idAccount: '',
    //   idAccountTo: '',
    //   idPayee: '',
    //   transactionDate: UtilDate.getDate(),
    //   isNew: true,
    // };
    // setlistData((ov) => {
    //   ov.unshift(obj);
    //   return [...ov];
    // });
    // seteditingRow(idTransaction);
    // setselectedRow(0);
    // form.setFieldsValue(obj);
  };

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      helmetTitle={`${t('WORD_TRANSACTIONS')} | ${budgetName} | ${t(
        'WORD_MONEYSCOPE'
      )} `}
      content={
        `${t('WORD_TRANSACTIONS')}` |
        `${budgetName}` |
        `${t('WORD_MONEYSCOPE')}`
      }
      headerProps={{
        right: (
          <MyDefaultDropdownButton
            title={'Add transaction'}
            titleIcon={<SVGPlusIcon />}
            dropdownMenuItems={[
              {
                key: 'import',
                label: t('WORD_IMPORT_TRANSACTION'),
                icon: <CloudUploadOutlined style={{ fontSize: 14 }} />,
              },
            ]}
            onItemClick={(item) => {
              seteditingRow(null);
              navigate('/transaction/import-xls');
            }}
            onClick={handleAddTransaction}
          />
        ),
      }}
    >
      <TransactionHeaderFilters
        filter={filter}
        setfilter={setfilter}
        selectedRowKeys={selectedRowKeys}
        openFilters={() => {
          filtersDrawerRef?.current?.showDrawer();
        }}
        onChangeApproved={(ids, approved) => {
          setlistData((ov) => {
            const newList = ov.map((tr) => {
              if (tr.idTransaction && ids.includes(tr.idTransaction)) {
                tr.approved = approved;
              }
              return tr;
            });
            return [...newList];
          });
          setSelectedRowKeys([]);
        }}
        onDeleteTransaction={(ids) => {
          setlistData((ov) => {
            const newList = ov.filter((tr) => !ids.includes(tr.idTransaction));
            return [...newList];
          });
          setSelectedRowKeys([]);
        }}
        defaultFilter={defaultFilter}
        drawerLoading={loading}
        filtersDrawerRef={filtersDrawerRef}
        inputSearchRef={inputSearchRef}
        tableRef={tableRef}
      />
      <FormApp name={formName} form={form} onFinish={onFinish}>
        <Flex vertical>
          <TableHeaderComponent
            filter={filter}
            setfilter={setfilter}
            totals={totals}
          />
          <InfiniteScrollTable
            ref={tableRef}
            height={'100%'}
            dataSource={listData}
            getScrollParent={() => layoutRef?.current}
            columns={columns}
            expandable={{
              expandIcon: () => null,
              columnWidth: 0,
              expandedRowKeys: [editingRow],
              expandedRowRender: (record, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: 12,
                  }}
                >
                  <div
                    style={{ display: 'flex', flexDirection: 'row', gap: 10 }}
                  >
                    <MyButtonSecondary
                      label="Cancel"
                      onClick={() => {
                        handleSetEditable(null);
                        setselectedRow('');
                        form.resetFields();
                        if (record?.isNew) {
                          setlistData((ov) => {
                            ov.splice(0, 1);
                            return [...ov];
                          });
                        }
                      }}
                    />
                    <MyButtonMain label="Save" form={formName} />
                  </div>
                </div>
              ),
              rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
            rowKey="idTransaction"
            selectedRow={selectedRow}
            dataLength={totalRows}
            loading={loading}
            onChange={onChangeTable}
            onRowDoubleClick={(index, record) => {
              handleSetEditable(record);
              setselectedRow(index);
            }}
            onRowClick={(index, record) => {
              let newList = [];
              setSelectedRowKeys((ov) => {
                const find = ov.find((row) => row === record.idTransaction);
                if (find) {
                  newList = ov.filter(
                    (idTransaction) => idTransaction !== record.idTransaction
                  );
                } else {
                  ov.push(record.idTransaction);
                  newList = ov;
                }
                return [...newList];
              });
            }}
            onLoadMore={() => {
              setloading(true);
              setfilter((ov) => Object.assign({}, ov, { page: ov.page + 1 }));
            }}
            rowSelection={{
              renderCell: (isSelected, item) => {
                return (
                  <div
                    style={{ height: 36 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedRowKeys((ov) => {
                        let newList = [];
                        if (!isSelected) {
                          ov.push(item.idTransaction);
                          newList = ov;
                        } else {
                          newList = ov.filter(
                            (idTransaction) =>
                              idTransaction !== item.idTransaction
                          );
                        }
                        return [...newList];
                      });
                    }}
                  >
                    {isSelected ? (
                      <SVGTableSelectedIcon />
                    ) : (
                      <GetTransactionIcons item={item} />
                    )}
                  </div>
                );
              },
              onChange: (list) => {
                setSelectedRowKeys(list);
              },
              selectedRowKeys: selectedRowKeys,
            }}
          />
        </Flex>
      </FormApp>
      <TransactionEditModal
        trigger={null}
        onSave={() => {
          if (filter?.page === 0) {
            fillFS();
          } else {
            setfilter((ov) => ({ ...ov, page: 0 }));
          }
        }}
        ref={refModal}
      />
    </MyDefaultPageLayout>
  );
};

export default TransactionList;
